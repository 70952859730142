import axios from 'axios';
import { useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

// import { setCookie } from 'src/utils/cookie';
import { refreshTokenAPI } from 'app/services/api/auth';
import moment from 'moment';

export const useAutoRefreshToken = () => {
  const refresh_token = localStorage.getItem('accessToken');
  const refreshToken = useCallback(async () => {
    if (refresh_token) {
      const params = {
        token: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      };
      try {
        const resp = await refreshTokenAPI(params);
        const validToken = resp?.token;

        localStorage.setItem('accessToken', validToken);
        localStorage.setItem('refreshToken', resp.refreshToken);
      } catch (err) {
        console.log({ err });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scheduleTokenRefresh = useCallback(
    currentToken => {
      let refreshTimer;
      try {
        if (currentToken) {
          const timeRefreshToken = moment((jwtDecode(currentToken)?.exp as any) * 1000).diff(moment(), 'minutes');
          localStorage.setItem('timeRefreshToken', timeRefreshToken.toString());
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [refreshToken],
  );

  return { refreshToken, scheduleTokenRefresh };
};
