import client from '.';

export const getWorkPackagesAPI = () => {
  return client.get('/ServiceCatelogue/get-list-work-package').then(res => res.data);
};

export const getSettingAPI = () => {
  return client.get('/ServiceCatelogue/get-work-package-setting').then(res => res.data);
};

export const getWorkPackagesDetailAPI = (id: number) => {
  return client.get(`/ServiceCatelogue/get-workpackage-detail?workPackageId=${id}`).then(res => res.data);
};

export const createAcceptanceCriteriaAPI = (param: any) => {
  return client.post(`/ServiceCatelogue/create-work-package`, param).then(res => res.data);
};

export const updateWorkPackageAPI = (param: any) => {
  return client.post(`/ServiceCatelogue/update-work-package`, param).then(res => res.data);
};

export const deleteServiceCatelogueAPI = (param: any) => {
  return client.delete(`/ServiceCatelogue/delete-work-package`, { data: param }).then(res => res.data);
};

export const reassignAPI = (param: any) => {
  return client.post(`/ServiceCatelogue/work-package-reassign`, param).then(res => res.data);
};

// ================== Step 2 ============================ //

export const getQuestionAnswerAPI = params => {
  return client.get('/ServiceCatelogue/get-question-answers', { params: params });
};

export const saveAnswerAPI = params => {
  return client.post('/ServiceCatelogue/save-answers', params);
};

export const submitForTestDesignAPI = params => {
  return client.post('/ServiceCatelogue/submit-for-test-design', params);
};

// ================== Step 3 ============================ //

export const getWorkPackageRequirementAPI = (id: number) => {
  return client.get(`/ServiceCatelogue/get-work-package-requirement?workPackageId=${id}`).then(res => res.data);
};

export const getTestDesignSpecificationAPI = (id: number) => {
  return client.get(`/ServiceCatelogue/get-requirement-test-condition?requirementId=${id}`).then(res => res.data);
};

// ================== Step 4 ============================ //

export const getServiceComponentsAPI = (id: number) => {
  return client.get(`/ServiceCatelogue/get-service-component?workPackageId=${id}`).then(res => res.data);
};
//step 5
export const getSummaryServiceCatalougeAPI = params => {
  return client.get('/ServiceCatelogue/get-summary-service-catalogue', { params: params });
};

export const updateServiceCatalougeItemAPI = params => {
  return client.post('/ServiceCatelogue/update-service-catalogue-item', params);
};

export const getQuoteDetailAPI = params => {
  return client.get('/ServiceCatelogue/get-quote-detail', { params: params });
};

export const applyPromocodeAPI = params => {
  return client.post('/ServiceCatelogue/apply-promocode', params);
};
export const sendQuotePDFAPI = params => {
  return client.post('/ServiceCatelogue/send-quote-pdf', params);
};
// end step 5

// step 6
export const approveWorkPackageAPI = params => {
  return client.post('/ServiceCatelogue/approved-work-package', null, { params: params });
};
export const cancelWorkPackageAPI = params => {
  return client.post('/ServiceCatelogue/cancel-work-package', null, { params: params });
};
// end step 6
