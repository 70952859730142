/**
 * Asynchronously loads the component for Batch
 */

import { lazyLoad } from 'utils/loadable';

export const Batch = lazyLoad(
  () => import('./index'),
  module => {
    return module.Batch;
  },
);
