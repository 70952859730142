import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import {
  addNewTestStepAPI,
  breakPointTestStepAPI,
  declareTransactionLoopAPI,
  deleteTestDataAPI,
  deleteTestScriptAPI,
  deleteTestStepAPI,
  editTestStepAPI,
  getComponentParentDataAPI,
  getFieldComboAPI,
  getReadOnlyTestStepEnginuityWorkbenchAPI,
  getTestDataEnginuityWorkbenchAPI,
  getTestStepEnginuityWorkbenchAPI,
  moveTestScriptAPI,
  selectedRunScriptAPI,
} from 'app/services/api/enginuityWorkbench';
import {
  addNewWorkBenchAPI,
  apiGetMiniDashboard,
  getLinkedDefectStatusAPI,
  getListRunHistoryAPI,
  getTestCaseDetailAPI,
  getTestCaseScopeAPI,
  getTestCaseSettingAPI,
  getTestCaseUsageAPI,
  getTestStepAPI,
  saveTestStepAPI,
  updateTestCaseAPI,
} from 'app/services/api/workbench';
import { DELETED_ID } from 'types/Common/DeletedID';
import { IResultRunTest, ITestScript } from './Workbench.type';

export interface IWorkBenchState {
  isLoading: boolean;
  testCaseEnginuitySelectedId: number;
  isUpdateTestCaseEnginuity: boolean;
  testCaseEnginuityDetail: any;
  testCaseEnginuitySetting: any;
  testCaseEnginuityMiniDashboard: any;
  isLoadingMiniDashboard: boolean;
  testCaseEnginuityScope: any;
  isLoadingTestCaseEnginuityScope: boolean;
  linkedDefectStatus: any;
  isLoadingLinkedDefectStatus: boolean;
  isUpdateTestStep: boolean;
  isChangeTestStep: boolean;
  isOpenModalConfirm: boolean;
  isDragable: boolean;
  listTestStepEnginuity: any;
  listReadOnlyTestStepEnginuity: any;
  isUpdateTestStepEnginuity: boolean;
  isNextSlide: boolean;
  testScriptSelectedId: number;
  isShowTestResultTestScript: boolean;
  isClickValidateSyntaxTestScript: boolean;
  isClickClearTestResultTestScript: boolean;
  isReloadListTestScript: boolean;
  testStepSelectedId: number;
  testTypeEnginuity: number;
  listTestDataEnginuity: any;
  isUpdateTestData: boolean;
  testCaseLinkId: number;
  listCopyTestStep: any;
  copyComponentId: number;
  copyComponentVersion: number;
  isLoadingStep: boolean;
  testStepSelectRow: any;
  testCaseId: number;
  copyTestStepActionMode: string;
  testDataSeletedRow: any;
  isMyTest: boolean;
  newTestStep: any;
  debugRunnerTestScript?: IResultRunTest | null;
  debugRunnerTestStep?: IResultRunTest | null;
  isRunningDebugRunner: boolean;
  isBreakingPoint: boolean;
  viewTypeTestStep: number;
  listTestScriptWhenBreakPoint: ITestScript[];
  isUpdateHistory: boolean;
  currentSelectedItem: any;
  totalDurationRunTestStep: string;
}

const initialState: IWorkBenchState = {
  isLoading: false,
  testCaseEnginuitySelectedId: 0,
  isUpdateTestCaseEnginuity: false,
  testCaseEnginuityDetail: {},
  testCaseEnginuitySetting: [],
  testCaseEnginuityMiniDashboard: [],
  isLoadingMiniDashboard: false,
  testCaseEnginuityScope: [],
  isLoadingTestCaseEnginuityScope: false,
  linkedDefectStatus: [],
  isLoadingLinkedDefectStatus: false,
  isUpdateTestStep: true,
  isChangeTestStep: false,
  isOpenModalConfirm: false,
  isDragable: true,
  listTestStepEnginuity: [],
  isUpdateTestStepEnginuity: false,
  isNextSlide: false,
  testScriptSelectedId: 0,
  isShowTestResultTestScript: false,
  isClickValidateSyntaxTestScript: false,
  isClickClearTestResultTestScript: false,
  isReloadListTestScript: false,
  listReadOnlyTestStepEnginuity: [],
  listTestDataEnginuity: [],
  testStepSelectedId: 0,
  testTypeEnginuity: 0,
  isUpdateTestData: false,
  testCaseLinkId: 0,
  listCopyTestStep: [],
  copyComponentId: 0,
  copyComponentVersion: 0,
  isLoadingStep: false,
  testStepSelectRow: {},
  testCaseId: 0,
  copyTestStepActionMode: '',
  testDataSeletedRow: {},
  isMyTest: false,
  newTestStep: {},
  debugRunnerTestScript: null,
  debugRunnerTestStep: null,
  isRunningDebugRunner: false,
  isBreakingPoint: false,
  viewTypeTestStep: 1,
  listTestScriptWhenBreakPoint: [],
  isUpdateHistory: false,
  currentSelectedItem: '',
  totalDurationRunTestStep: '',
};

export const getTestCaseDetail = createAsyncThunk('testCase/getTestCaseDetail', async (params: any) => {
  const response = await getTestCaseDetailAPI(params);
  return response;
});

export const getTestCaseSetting = createAsyncThunk('testCase/getTestCaseSetting', async (mode: number) => {
  const response = await getTestCaseSettingAPI(mode);
  return response;
});

export const getTestCaseUsage = createAsyncThunk('testCase/getTestCaseUsage', async (id: number) => {
  const response = await getTestCaseUsageAPI(id);
  return response;
});

export const getListRunHistory = createAsyncThunk('testCase/getListRunHistory', async (id: number, mode: any) => {
  const response = await getListRunHistoryAPI(id, mode);
  return response;
});

export const addNewWorkBench = createAsyncThunk('testCase/addNewWorkBench', async (params: any) => {
  try {
    const res: any = await addNewWorkBenchAPI(params);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', error?.errors?.TestCaseTitle || error?.errors?.TestCaseDescription || error);
  }
});

export const updateTestCase = createAsyncThunk('testCase/updateTestCase', async (params: any) => {
  try {
    const res: any = await updateTestCaseAPI(params);
    if (res) {
      openNotificationWithIcon('success', res.data, 12);
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', typeof error == 'string' ? error : error?.message);
  }
});

export const getTestCaseEnginuityDashboard = createAsyncThunk(
  'testCaseEnginuity/getTestCaseDashboard',
  async (id: number) => {
    const response = await apiGetMiniDashboard(id);
    return response;
  },
);

export const getTestCaseScope = createAsyncThunk('testCase/getTestCaseScope', async (id: number) => {
  const response = await getTestCaseScopeAPI(id);
  return response;
});

export const getLinkedDefectStatus = createAsyncThunk('testCase/getLinkedDefectStatus', async (id: number) => {
  const response = await getLinkedDefectStatusAPI(id);
  return response;
});

// Test Step ..............
export const getTestStep = createAsyncThunk('testCase/getTestStep', async (id: number) => {
  const response = await getTestStepAPI(id);
  return response.data;
});

export const saveTestStep = createAsyncThunk('testCase/saveTestStep', async (id: number) => {
  const response = await saveTestStepAPI(id);
  return response.data;
});

// Test Script ................... //
export const deleteTestScript = createAsyncThunk('testCase/deleteTestScript', async (params: any) => {
  const response = await deleteTestScriptAPI(params);
  return response.data;
});

export const moveTestScript = createAsyncThunk('testCase/moveTestScript', async (params: any) => {
  const response = await moveTestScriptAPI(params);
  return response.data;
});

export const selectedRunScript = createAsyncThunk('testCase/selectedRunScript', async (params: any) => {
  const response = await selectedRunScriptAPI(params);
  return response.data;
});

// Test Step ................... //
export const getTestStepEnginuityWorkbench = createAsyncThunk(
  'testCase/getTestStepEnginuityWorkbench',
  async (param: {
    componentId: number;
    intTestCaseLinkId: number;
    componentVersion: number;
    isLoadResult: boolean;
  }) => {
    const response = await getTestStepEnginuityWorkbenchAPI(param);
    return response.data;
  },
);

export const getReadOnlyTestStepEnginuityWorkbench = createAsyncThunk(
  'testCase/getReadOnlyTestStepEnginuityWorkbench',
  async (param: { componentId: number; intTestCaseLinkId: number; componentVersion: number }) => {
    const response = await getReadOnlyTestStepEnginuityWorkbenchAPI(param);
    return response.data;
  },
);

export const getFieldCombo = createAsyncThunk(
  'testCase/getFieldCombo',
  async (param: { componentId: number; componentVersion: number; testStepId: number; actionWord: string }) => {
    const response = await getFieldComboAPI(param);
    return response.data;
  },
);

export const addNewTestStep = createAsyncThunk('testCase/addNewTestStep', async (param: any) => {
  try {
    const response = await addNewTestStepAPI(param);
    openNotificationWithIcon('success', response);
    return response.data;
  } catch (error) {
    openNotificationWithIcon('error', error);
  }
});

export const editTestStep = createAsyncThunk('testCase/editTestStep', async (param: any) => {
  const response = await editTestStepAPI(param);
  return response.data;
});

export const deleteTestStep = createAsyncThunk('testCase/deleteTestStep', async (param: any) => {
  const response = await deleteTestStepAPI(param);
  return response.data;
});

export const breakPointTestStep = createAsyncThunk(
  'testCase/breakPointTestStep',
  async (param: { testStepInstance: number; breakPoint: boolean }) => {
    const response = await breakPointTestStepAPI(param);
    return response.data;
  },
);

export const declareTransactionLoop = createAsyncThunk('testCase/declareTransactionLoop', async (param: any) => {
  const response = await declareTransactionLoopAPI(param);
  return response.data;
});
// =========================================== //

// ============= Test Data ================== //
export const getTestDataEnginuityWorkbench = createAsyncThunk(
  'testCase/getTestDataEnginuityWorkbench',
  async (param: { componentId: number; testCaseLinkId: number; componentVersion: number }) => {
    const response = await getTestDataEnginuityWorkbenchAPI(param);
    return response.data;
  },
);

export const getComponentParentData = createAsyncThunk(
  'testCase/getComponentParentData',
  async (componentId: number) => {
    const response = await getComponentParentDataAPI(componentId);
    return response.data;
  },
);

export const deleteTestData = createAsyncThunk('testCase/deleteTestData', async (param: any) => {
  const response = await deleteTestDataAPI(param);
  return response.data;
});
// ========================================== //

export const authoriseWorkbenchSlice = createSlice({
  name: 'enginuityWorkbench',
  initialState,
  reducers: {
    setTestCaseEnginuitySelectedId: (state, action) => {
      if (!state.isUpdateTestStep) return;
      state.testCaseEnginuitySelectedId = action.payload;
      state.testStepSelectedId = action.payload;
      state.testScriptSelectedId = 0;
    },
    triggerUpdateTestCase: state => {
      state.isUpdateTestCaseEnginuity = !state.isUpdateTestCaseEnginuity;
    },
    triggerNextSlide: (state, action) => {
      state.isNextSlide = action.payload;
    },
    triggerLoadingMiniDashboard: state => {
      state.isLoadingMiniDashboard = !state.isLoadingMiniDashboard;
    },
    triggerLoadingTestCaseScope: state => {
      state.isLoadingTestCaseEnginuityScope = !state.isLoadingTestCaseEnginuityScope;
    },
    triggerLoadingLinkedDefectStatus: state => {
      state.isLoadingLinkedDefectStatus = !state.isLoadingLinkedDefectStatus;
    },
    triggerUpdateTestStep: (state, action) => {
      state.isUpdateTestStep = action.payload;
    },
    triggerChangeTestStep: (state, action) => {
      state.isChangeTestStep = action.payload;
    },
    triggerOpenModalConfirm: (state, action) => {
      state.isOpenModalConfirm = action.payload;
    },
    setIsDragable: (state, action) => {
      state.isDragable = action.payload;
    },
    triggerUpdateTestStepEnginuity: state => {
      state.isUpdateTestStepEnginuity = !state.isUpdateTestStepEnginuity;
    },
    triggerUpdateTestData: state => {
      state.isUpdateTestData = !state.isUpdateTestData;
    },
    setTestScriptSelectedId: (state, action) => {
      state.testScriptSelectedId = action.payload;
    },
    setTestStepSelectedId: (state, action) => {
      state.testStepSelectedId = action.payload;
    },
    updateTestCaseLinkId: (state, action) => {
      state.testCaseLinkId = action.payload;
    },
    updateTestCaseId: (state, action) => {
      state.testCaseId = action.payload;
    },
    setListCopyTestStep: (state, action) => {
      state.listCopyTestStep = action.payload.listCopyTestStep;
      state.copyComponentId = action.payload.copyComponentId;
      state.copyComponentVersion = action.payload.copyComponentVersion;
      state.copyTestStepActionMode = action.payload.copyTestStepActionMode;
    },
    triggerReloadListTestScript: state => {
      state.isReloadListTestScript = !state.isReloadListTestScript;
    },
    triggerIsLoadingStep: state => {
      state.isLoadingStep = !state.isLoadingStep;
    },
    setTestStepSelectRow: (state, action) => {
      state.testStepSelectRow = action.payload;
    },
    setTestDataSeletedRow: (state, action) => {
      state.testDataSeletedRow = action.payload;
    },
    changeShowTestResultTestScript: (state, action) => {
      state.isShowTestResultTestScript = action.payload;
    },
    triggerClickValidateTestScript: (state, action) => {
      state.isClickValidateSyntaxTestScript = action.payload;
    },
    triggerClickClearTestResultTestScript: (state, action) => {
      state.isClickClearTestResultTestScript = action.payload;
    },
    clearTestCaseEnginuity: state => {
      state.testCaseEnginuityDetail = {};
    },
    setMyTest: (state, action) => {
      state.isMyTest = action.payload;
    },
    setNewSelectedRowTestStep: (state, action) => {
      state.newTestStep = action.payload;
    },
    setDebugRunnerTestScript: (state, action) => {
      state.debugRunnerTestScript = action.payload;
    },
    setDebugRunnerTestStep: (state, action) => {
      state.debugRunnerTestStep = action.payload;
    },
    setIsRunningDebugRunner: (state, action) => {
      state.isRunningDebugRunner = action.payload;
    },
    setIsBreakingPoint: (state, action) => {
      state.isBreakingPoint = action.payload;
    },
    setViewTypeTestStep: (state, action) => {
      state.viewTypeTestStep = action.payload;
    },
    setListTestScriptWhenBreakPoint: (state, action) => {
      state.listTestScriptWhenBreakPoint = action.payload;
    },
    triggerUpdateHistory: state => {
      state.isUpdateHistory = !state.isUpdateHistory;
    },
    setCurrentSelectedItem: (state, action) => {
      state.currentSelectedItem = action.payload;
    },
    setTotalDurationRunTestStep: (state, action) => {
      state.totalDurationRunTestStep = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getTestCaseDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseEnginuityDetail = action.payload;
    });

    builder.addCase(getTestCaseDetail.rejected, (state, action) => {
      if (action?.error?.message?.includes('has been deleted')) {
        state.testCaseEnginuitySelectedId = DELETED_ID;
        state.testCaseEnginuityDetail = null;
      } else {
        state.testCaseEnginuitySelectedId = 0;
      }
      state.isUpdateTestCaseEnginuity = !state.isUpdateTestCaseEnginuity;
    });

    builder.addCase(getTestCaseEnginuityDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseEnginuityMiniDashboard = action.payload?.rs?.miniDashboardItems;
      state.isLoadingMiniDashboard = false;
    });

    builder.addCase(getTestCaseSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseEnginuitySetting = action.payload;
    });

    builder.addCase(getTestCaseScope.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseEnginuityScope = action.payload;
      state.isLoadingTestCaseEnginuityScope = false;
    });

    builder.addCase(getLinkedDefectStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.linkedDefectStatus = action.payload;
      state.isLoadingLinkedDefectStatus = false;
    });

    builder.addCase(saveTestStep.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdateTestStep = false;
      state.isChangeTestStep = false;
    });

    // =========== Step =================== //
    builder.addCase(getTestStepEnginuityWorkbench.fulfilled, (state, action) => {
      state.isLoading = false;
      state.listTestStepEnginuity = state.testStepSelectedId == 0 ? [] : action.payload.data;
      state.isLoadingStep = false;
    });

    builder.addCase(getReadOnlyTestStepEnginuityWorkbench.fulfilled, (state, action) => {
      state.isLoading = false;
      state.listReadOnlyTestStepEnginuity = action.payload.data;
    });

    // =========== Step =================== //
    builder.addCase(getTestDataEnginuityWorkbench.fulfilled, (state, action) => {
      state.isLoading = false;
      state.listTestDataEnginuity = action.payload.data;
    });
  },
});

// export reducer action
export const {
  setTestCaseEnginuitySelectedId,
  triggerUpdateTestCase,
  triggerNextSlide,
  triggerLoadingMiniDashboard,
  triggerLoadingTestCaseScope,
  triggerLoadingLinkedDefectStatus,
  triggerUpdateTestStep,
  triggerChangeTestStep,
  triggerOpenModalConfirm,
  setIsDragable,
  triggerUpdateTestStepEnginuity,
  setTestScriptSelectedId,
  setTestStepSelectedId,
  triggerUpdateTestData,
  updateTestCaseLinkId,
  setListCopyTestStep,
  triggerReloadListTestScript,
  triggerIsLoadingStep,
  setTestStepSelectRow,
  updateTestCaseId,
  setTestDataSeletedRow,
  changeShowTestResultTestScript,
  clearTestCaseEnginuity,
  setMyTest,
  setNewSelectedRowTestStep,
  triggerClickValidateTestScript,
  triggerClickClearTestResultTestScript,
  setDebugRunnerTestScript,
  setDebugRunnerTestStep,
  setIsRunningDebugRunner,
  setIsBreakingPoint,
  setViewTypeTestStep,
  setListTestScriptWhenBreakPoint,
  triggerUpdateHistory,
  setCurrentSelectedItem,
  setTotalDurationRunTestStep,
} = authoriseWorkbenchSlice.actions;

export default authoriseWorkbenchSlice.reducer;
