import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSettingAPI } from 'app/services/api/bug';
import { IListDocs } from './DocsManagement.type';

export interface docsManagement {
  docsManagementDeatil: IListDocs;
}
const initialState: docsManagement = {
  docsManagementDeatil: {} as Required<IListDocs>,
};

export const docsManagementSlice = createSlice({
  name: 'docsManagement',
  initialState,

  reducers: {
    triggerIsUpdateTestCase: state => {},
    setDocsManagementDeatil: (state, action) => {
      state.docsManagementDeatil = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { triggerIsUpdateTestCase, setDocsManagementDeatil } = docsManagementSlice.actions;

export default docsManagementSlice.reducer;
