/**
 * Asynchronously loads the component for ReleasePage
 */

import { lazyLoad } from 'utils/loadable';

export const Workbench = lazyLoad(
  () => import('./index'),
  module => {
    return module.Workbench;
  },
);
