import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import {
  addAcceptanceCriteriaTestCaseAPI,
  getAcceptanceCriteriaDetailAPI,
  getAcceptanceIssueStatusAPI,
} from 'app/services/api/acceptanceCriteria';
import { DELETED_ID } from 'types/Common/DeletedID';

export interface AcceptanceCriteriaState {
  isLoading: boolean;
  acceptanceCriteriaSelectedId: number;
  isUpdateAcceptanceCriteria: boolean;
  acceptanceCriteriaDetail: any;
  acceptanceCriteriaSetting: any;
  isUpdateFeatureFile: boolean;
  isUpdateLinkedArticle: boolean;
  isLoadingAcceptanceIssueStatus: boolean;
  isAssignToMe: boolean;
  isUpdateHistory: boolean;
  isLoadingTestCase: boolean;
}

const initialState: AcceptanceCriteriaState = {
  isLoading: false,
  acceptanceCriteriaSelectedId: 0,
  isUpdateAcceptanceCriteria: false,
  acceptanceCriteriaDetail: [],
  acceptanceCriteriaSetting: undefined,
  isUpdateFeatureFile: false,
  isUpdateLinkedArticle: false,
  isLoadingAcceptanceIssueStatus: false,
  isAssignToMe: true,
  isUpdateHistory: false,
  isLoadingTestCase: true,
};

export const getAcceptanceCriteriaDetail = createAsyncThunk('testCase/getAcceptanceCriteriaDetail', async (id: any) => {
  const response = await getAcceptanceCriteriaDetailAPI(id);
  return response;
});

export const addAcceptanceCriteriaTestCase = createAsyncThunk(
  'testCase/addAcceptanceCriteriaTestCase',
  async (params: any) => {
    try {
      const res: any = await addAcceptanceCriteriaTestCaseAPI(params);
      if (res) {
        return res.data;
      }
    } catch (error: any) {
      openNotificationWithIcon('error', error?.errors?.TestCaseTitle || error?.errors?.TestCaseDescription || error);
    }
  },
);

export const getAcceptanceIssueStatus = createAsyncThunk('testCase/getLinkedDefectStatus', async (id: number) => {
  const response = await getAcceptanceIssueStatusAPI(id);
  return response;
});

export const acceptanceCriteriaSlice = createSlice({
  name: 'acceptanceCriteria',
  initialState,
  reducers: {
    setAcceptanceCriteriaSelectedId: (state, action) => {
      state.acceptanceCriteriaSelectedId = action.payload;
    },
    setAcceptanceCriteriaSelectedDetail: (state, action) => {
      state.acceptanceCriteriaDetail = action.payload;
    },
    setAcceptanceCriteriaSetting: (state, action) => {
      state.acceptanceCriteriaSetting = action.payload;
    },
    triggerUpdateAcceptanceCriteria: state => {
      state.isUpdateAcceptanceCriteria = !state.isUpdateAcceptanceCriteria;
    },
    triggerUpdateFeatureFile: state => {
      state.isUpdateFeatureFile = !state.isUpdateFeatureFile;
    },
    triggerUpdateLinkedArticle: state => {
      state.isUpdateLinkedArticle = !state.isUpdateLinkedArticle;
    },
    triggerLoadingAcceptanceIssueStatus: state => {
      state.isLoadingAcceptanceIssueStatus = !state.isLoadingAcceptanceIssueStatus;
    },
    setAssignToMe: (state, action) => {
      state.isAssignToMe = action.payload;
    },
    triggerUpdateHistory: state => {
      state.isUpdateHistory = !state.isUpdateHistory;
    },
    triggerLoadingTestCase: state => {
      state.isLoadingTestCase = !state.isLoadingTestCase;
    },
  },

  extraReducers: builder => {
    builder.addCase(getAcceptanceCriteriaDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.acceptanceCriteriaDetail = action.payload;
    });

    builder.addCase(getAcceptanceCriteriaDetail.rejected, (state, action: any) => {
      state.isLoading = false;
      state.acceptanceCriteriaDetail = action.payload;
      if (action?.error?.message.includes('has been deleted or not existed')) {
        state.acceptanceCriteriaSelectedId = DELETED_ID;
        state.isUpdateAcceptanceCriteria = !state.isUpdateAcceptanceCriteria;
      }
      openNotificationWithIcon('error', action?.error?.message);
    });

    builder.addCase(getAcceptanceIssueStatus.fulfilled, (state, action) => {
      state.isLoadingAcceptanceIssueStatus = false;
    });
  },
});

export const {
  setAcceptanceCriteriaSelectedId,
  setAcceptanceCriteriaSetting,
  triggerUpdateAcceptanceCriteria,
  triggerUpdateFeatureFile,
  setAcceptanceCriteriaSelectedDetail,
  triggerUpdateLinkedArticle,
  triggerLoadingAcceptanceIssueStatus,
  setAssignToMe,
  triggerUpdateHistory,
  triggerLoadingTestCase,
} = acceptanceCriteriaSlice.actions;

export default acceptanceCriteriaSlice.reducer;
