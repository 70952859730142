import client from '.';

export const getExecutionGridViewAPI = params => {
  return client.get(`/ExecutionSetRegister/get-execution-grid-view`, { params: params }).then(res => res.data);
};

/* api/ExecutionSet/get-execution-set-setting */
export const getExecutionSetting = () => {
  return client.get(`/ExecutionSet/get-execution-set-setting`).then(res => res.data);
};

/* api/ExecutionSet/create-update-execution */
export const createOrUpdateExecutionSetting = params => {
  return client.post('ExecutionSet/create-update-execution', params);
};
export const getExecutionCycleDetailAPI = (id: number) => {
  return client.get(`/ExecutionSet/get-execution-detail?executionSetId=${id}`).then(res => res.data);
};

export const getExecutionSummaryAPI = (id: number) => {
  return client.get(`/ExecutionSet/get-execution-summary?executionSetId=${id}`).then(res => res.data);
};

export const getMiniDashboardAPI = (id: number) => {
  return client.get(`/ExecutionSet/get-execution-minidashboard?executionSetId=${id}`).then(res => res.data);
};

export const updateExecutionCycleAPI = (params: any) => {
  return client.post(`ExecutionSet/create-update-execution`, params).then(res => res.data);
};

export const deleteExecutionCycleAPI = params => {
  return client.delete(`ExecutionSet/delete-execution-set`, { data: params }).then(res => res.data);
};

/* /api/ExecutionSet/execution-workflow-${title} */
export const updateExecutionWorkflowAPI = (title: string, param: any) => {
  return client.post(`/ExecutionSet/execution-workflow-${title}`, { ...param }).then(res => res.data);
};

// start activity
export const getExecutionSetCoverageAPI = params => {
  return client.get('/ExecutionSetCoverage/get-execution-coverage', { params: params });
};

export const assignTestCaseAPI = params => {
  return client.post('/ExecutionSetCoverage/assign-testcase-to-execution', params);
};

export const deleteTestCaseAPI = params => {
  return client.delete('/ExecutionSetCoverage/delete-testcase-from-execution', { data: params });
};

export const getTestCaseAllocationAPI = params => {
  return client.get('/ExecutionSetCoverage/get-testcase-allocation', { params: params }).then(res => res.data);
};

export const updateTestCaseAllocationAPI = params => {
  return client.post('/ExecutionSetCoverage/update-testcase-allocation', params).then(res => res.data);
};

export const getTestCaseAllocationDetailAPI = params => {
  return client.get('/ExecutionSetCoverage/get-testcase-allocation-detail', { params: params }).then(res => res.data);
};
// end activity
