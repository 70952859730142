import { createSlice } from '@reduxjs/toolkit';
import { ICurrentEditItem, IListLicenceType, IListProductSelection } from './LicenceManagement.type';

interface KeyValueTypes {
  key: string;
  value: string;
}

export interface LicenceManagementState {
  isLoading: boolean;
  isLoadingLicencedRequests: boolean;
  isLoading123: boolean;
  listLicenceType: IListLicenceType[] | KeyValueTypes[];
  currentIndexLicenceType: ICurrentEditItem | undefined;
  currentLicenceType: string;
  productPricingDetail: any;
  subTitle: string;
  modeLicenceDetails: string;
  selectProduct: any;
  licenceProducts: any;
  childIndexForUse: any;
  dataOptions: any;
  isDisableBtn: boolean;
  dataLicenceRequestByCompanyId: any;
  currentLicenceRequestId: string | null;
  currentLicenceRequestItem: any;
  licenceDetail: any;
  isBackFromStep2: boolean;
  listProductSelection: IListProductSelection[];
  currentIndexEdit: number;
  activeTab: any;
  currentLicenceProduct: any;
}

const initialState: LicenceManagementState = {
  isLoading: false,
  isLoadingLicencedRequests: false,
  isLoading123: false,
  listLicenceType: [],
  currentIndexLicenceType: undefined,
  currentLicenceType: '',
  productPricingDetail: {},
  subTitle: '',
  modeLicenceDetails: '',
  selectProduct: null,
  licenceProducts: [],
  childIndexForUse: null,
  dataOptions: [],
  isDisableBtn: true,
  dataLicenceRequestByCompanyId: [],
  currentLicenceRequestId: null,
  currentLicenceRequestItem: null,
  licenceDetail: undefined,
  isBackFromStep2: false,
  listProductSelection: [],
  currentIndexEdit: 0,
  activeTab: null,
  currentLicenceProduct: null,
};

export const licenceManagementSlice = createSlice({
  name: 'LicenceManagement',
  initialState,
  reducers: {
    setLicenceType: (state, action) => {
      state.listLicenceType = action.payload;
    },
    setCurrentLicenceType: (state, action) => {
      state.currentLicenceType = action.payload;
    },
    setCurrentIndexLicenceType: (state, action) => {
      state.currentIndexLicenceType = action.payload;
    },
    setProductPricingDetail: (state, action) => {
      state.productPricingDetail = action.payload;
    },
    setSubTitle: (state, action) => {
      state.subTitle = action.payload;
    },
    setModeLicenceDetails: (state, action) => {
      state.modeLicenceDetails = action.payload;
    },
    setSelectProduct: (state, action) => {
      state.selectProduct = action.payload;
    },
    setIsDisableButton: state => {
      // state.isDisableButton = !state.isDisableButton;
    },
    triggerLoadingLicencedRequests: state => {
      state.isLoadingLicencedRequests = !state.isLoadingLicencedRequests;
    },
    setLicenceProducts: (state, action) => {
      state.licenceProducts = action.payload;
    },
    setChildIndexForUse: (state, action) => {
      state.childIndexForUse = action.payload;
    },
    setDataOption: (state, action) => {
      state.dataOptions = action.payload;
    },
    setIsDisableBtn: (state, action) => {
      state.isDisableBtn = action.payload;
    },
    setCurrentLicenceRequestId: (state, action) => {
      state.currentLicenceRequestId = action.payload;
    },
    setCurrentLicenceRequestItem: (state, action) => {
      state.currentLicenceRequestItem = action.payload;
    },
    setDataLicenceRequestByCompanyId: (state, action) => {
      state.dataLicenceRequestByCompanyId = action.payload;
    },
    setlicenceDetail: (state, action) => {
      state.licenceDetail = action.payload;
    },
    setIsBackFromStep2: (state, action) => {
      state.isBackFromStep2 = action.payload;
    },
    setListProductSelection: (state, action) => {
      state.listProductSelection = action.payload;
    },
    setCurrentIndexEdit: (state, action) => {
      state.currentIndexEdit = action.payload;
    },
    setCurrentActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setCurrentLicenceProduct: (state, action) => {
      state.currentLicenceProduct = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const {
  setLicenceType,
  triggerLoadingLicencedRequests,
  setCurrentLicenceType,
  setCurrentIndexLicenceType,
  setProductPricingDetail,
  setSubTitle,
  setModeLicenceDetails,
  setSelectProduct,
  setIsDisableButton,
  setLicenceProducts,
  setChildIndexForUse,
  setDataOption,
  setIsDisableBtn,
  setCurrentLicenceRequestId,
  setCurrentLicenceRequestItem,
  setDataLicenceRequestByCompanyId,
  setlicenceDetail,
  setIsBackFromStep2,
  setListProductSelection,
  setCurrentIndexEdit,
  setCurrentActiveTab,
  setCurrentLicenceProduct,
} = licenceManagementSlice.actions;
export default licenceManagementSlice.reducer;
