import client from '.';

// Batch Scheduling Register
export const getBatchRegisterGridViewAPI = params => {
  return client.get('/BatchSchedulingRegister/get-batch-chart', { params: params });
};

export const getBatchTestStepImageAPI = params => {
  return client.get('/BatchScheduling/get-batch-test-step-image', { params: params });
};
// end Batch Scheduling Register

export const deleteBatchAPI = params => {
  return client.delete('/BatchScheduling/delete-batch', { data: params });
};

export const getBatchScopeAPI = params => {
  return client.get('/BatchScope/get-batch-scope', { params: params });
};

export const deleteBatchTestCaseCoverageAPI = params => {
  return client.post('/BatchScope/detete-batch-testcase-coverage', params);
};

export const assignBatchTestCaseCoverageAPI = params => {
  return client.post('/BatchScope/assign-batch-testcase-coverage', params);
};

export const getAcceptanceCoverageAPI = params => {
  return client.get('/BatchScope/get-acceptance-coverage', { params: params });
};

export const deteteBatchAcceptanceCoverageAPI = params => {
  return client.post('/BatchScope/detete-batch-acceptance-coverage', params);
};

export const assignBatchAcceptanceCoverageAPI = params => {
  return client.post('/BatchScope/assign-batch-acceptance-coverage', params);
};

export const getBatchSetiingAPI = () => {
  return client.get('BatchScheduling/get-batch-settings');
};

export const getBatchDetailAPI = id => {
  return client.get(`BatchScheduling/get-batch-detail?batchId=${id}`);
};

export const getBatchConfiguringDeatilAPI = id => {
  return client.get(`BatchConfiguring/get-batch-configuring?batchId=${id}`);
};

export const addNewBatchAPI = params => {
  return client.post('BatchScheduling/create-batch', params);
};

export const updateBatchAPI = params => {
  return client.post('BatchScheduling/update-batch', params);
};

export const cloneAndCopyAPI = param => {
  return client.post('BatchScheduling/clone-batch', param);
};

export const workflowConfiguringAPI = id => {
  return client.post(`BatchScheduling/workflow-configuring?batchId=${id}`);
};

export const workflowScheduleApprovalAPI = param => {
  return client.post(`BatchScheduling/workflow-schedule-approval?batchId=${param?.batchId}&batchTotalTests=0`);
};

export const batchExecutionDetailsAPI = (id: number) => {
  return client.get(`BatchScheduling/get-batch-execution-detail?batchId=${id}`);
};

export const minidashboardAPI = (id: number) => {
  return client.get(`BatchScheduling/get-batch-mini-dashboard?batchId=${id}`);
};

// ================ Batch Configuring ========================= //

export const updateBatchConfiguringSetting = param => {
  return client.post('BatchConfiguring/update-batch-setting', param);
};

export const updateBatchConfiguringStream = param => {
  return client.post('BatchConfiguring/update-batch-stream-selected', param);
};

// ================ Defect Triage =========================== //

export const getDefectTriageDeatilAPI = (param: any) => {
  return client.get(
    `DefectTriage/get-defect-triage?page=${param?.page}&pageRows=${param?.pageRows}&isPaging=${param?.isPaging}&isOpenSelected=${param?.isOpenSelected}&isNewSelected=${param?.isNewSelected}&isDeferredSelected=${param?.isDeferredSelected}&isClosedSelected=${param?.isClosedSelected}&&searchString=${param?.searchString}`,
  );
};

export const minidashboardDefectAPI = () => {
  return client.get('DefectTriage/get-triage-mini-dashboard');
};

export const updateDefectTriageAPI = param => {
  return client.post(`DefectTriage/update-defect-triage`, param);
};

export const getDefectErrorImage = id => {
  return client.get(`BatchScheduling/get-batch-test-step-image?testStepInstanceId=${id}`);
};
// Batch Execution API
export const getBatchExecutionDetailsAPI = params => {
  return client.get('/BatchExecution/get-batch-execution-details', { params: params });
};

export const createNewDefectBatchExecutionAPI = params => {
  return client.post('/BatchExecution/create-new-defect', params);
};

export const changeTestStepStatusBatchExecutionAPI = params => {
  return client.post('/BatchExecution/change-test-step-status', params);
};

export const generateDefectsBatchExecutionAPI = params => {
  return client.post('/BatchExecution/generate-defects', params);
};

// End Batch Execution API
