import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISelectUser } from 'app/models/userStories.model';
import { IPermissionResult, IUserRoleDetail } from '../Setting/RolesAndPermission/Setting.type';

export interface AuthState {
  user?: any;
  userDetail: IUserRoleDetail | null;
  accessToken: string;
  listUser: ISelectUser[];
  userPermissions: string[];
  webModulePermission: IPermissionResult[];
  loginScreen: number;
  listProductLicenceSelect: any;
  selectProduct: any;
  isUpdateProfile: boolean;
  loginSuccess: any;
  tokenParse: any;
}

const initialState: AuthState = {
  user: null,
  userDetail: null,
  accessToken: '',
  listUser: [],
  userPermissions: [],
  webModulePermission: [],
  loginScreen: 1,
  listProductLicenceSelect: [],
  selectProduct: [],
  isUpdateProfile: false,
  loginSuccess: null,
  tokenParse: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },
    setListUser: (state, action) => {
      state.listUser = action.payload;
    },
    setUserPermissions: (state, action) => {
      let authToken = action.payload;
      if (authToken) {
        var base64Url = authToken.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
        );
        let jsonData = JSON.parse(jsonPayload);
        state.tokenParse = jsonData;
        state.userPermissions = jsonData?.Permissions ?? [];
        state.webModulePermission = jsonData?.WebModulePermission ? JSON.parse(jsonData?.WebModulePermission) : [];
      }
    },
    setUserPermissionWhenReloadPage: (state, action) => {
      state.webModulePermission = action.payload;
    },
    setLoginScreen: (state, action) => {
      state.loginScreen = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setLoginSuccess: (state, action) => {
      state.loginSuccess = action.payload;
    },
    setListProductLicenceSelect: (state, action) => {
      state.listProductLicenceSelect = action.payload;
    },
    setSelectProduct: (state, action) => {
      state.selectProduct = action.payload;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setIsUpdateProfile: state => {
      state.isUpdateProfile = !state.isUpdateProfile;
    },
  },
  extraReducers: {},
});

export const {
  loginSuccess,
  setListUser,
  setUserPermissions,
  setUserPermissionWhenReloadPage,
  setLoginScreen,
  setAccessToken,
  setLoginSuccess,
  setListProductLicenceSelect,
  setSelectProduct,
  setUserDetail,
  setIsUpdateProfile,
} = authSlice.actions;

export default authSlice.reducer;
