/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
// import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { usePermission } from './hooks/usePermission.hook';
import Main from './layout/Main';
import { ActionType, ModuleName } from './models/permission.model';
import NoAuthRoute from './NoAuthRoute';
import { Acceptance } from './pages/Acceptance/Loadable';
import { AcceptanceCriteria } from './pages/AcceptanceCriteria/Loadable';
import { AI_ML } from './pages/AI&ML/Loadable';
import { Batch } from './pages/Batch/Loadable';
import { AuthoriseBatch } from './pages/AuthoriseBatch/Loadable';
import { Bugs } from './pages/Bugs/Loadable';
import { Dashboard } from './pages/Dashboard/Loadable';
import { DecisionTable } from './pages/DecisionTable/Loadable';
import { DocumentConvert } from './pages/DocumentConvert/Loadable';
import { DocumentManagement } from './pages/DocumentManagement/Loadable';
import { EnginuityWorkbench } from './pages/EnginuityWorkbench/Loadable';
import { AuthoriseWorkbench } from './pages/AuthoriseWorkbench/Loadable';
import { ForbiddenPage } from './pages/ErrorPage/Loadable';
import { Execute } from './pages/Execute/Loadable';
import ExecuteTestCase from './pages/Execute/TestCaseSteps/ExecuteTestCase';
import { HomePage } from './pages/HomePage/Loadable';
import { Iframe } from './pages/Iframe';
import { LoginPage } from './pages/LoginPage/Loadable';
import { MSA } from './pages/MSA/Loadable';
import NewPassword from './pages/NewPassword';
import { ReleasePage } from './pages/ReleasePage/Loadable';
import { Reports } from './pages/Reports/Loadable';
import { LicenceManagement } from './pages/Setting/LicenceManagement/Loadable';
import { ProjectManagement } from './pages/Setting/ProjectManagement/Loadable';
import { Setting } from './pages/Setting/RolesAndPermission/Loadable';
import PermissionDetail from './pages/Setting/RolesAndPermission/Permission/PermissionDetail/PermissionDetail';
import RoleDetail from './pages/Setting/RolesAndPermission/Role/RoleDetail/RoleDetail';
import UserDetail from './pages/Setting/RolesAndPermission/User/UserDetail/UserDetail';
import { SignUpPage } from './pages/SignUpPage';
import { TestDesign } from './pages/TestDesign/Loadable';
import { TestLab } from './pages/TestLab/Loadable';
import { UserStories } from './pages/UserStories/Loadable';
import { VirtualTesterMonitor } from './pages/VirtualTesterMonitor/Loadable';
import { Workbench } from './pages/Workbench/Loadable';
import PrivateRoute from './PrivateRoute';
import { RootState, useAppSelector } from './store';

export function App() {
  const { isHavePermission } = usePermission();
  const user = useAppSelector((state: RootState) => state.auth.user);

  return (
    <BrowserRouter>
      <Switch>
        <NoAuthRoute exact path="/login" component={LoginPage} />
        <NoAuthRoute exact path="/auth/sign-up" component={SignUpPage} />
        <NoAuthRoute exact path="/auth/reset-password" component={NewPassword} />
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Main>
          <PrivateRoute path="/home" component={HomePage} hasPermission={true} />

          <PrivateRoute
            path="/release"
            component={ReleasePage}
            hasPermission={isHavePermission(ModuleName.Release, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/requirement"
            component={UserStories}
            hasPermission={isHavePermission(ModuleName.UserStories, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/defect"
            component={Bugs}
            hasPermission={isHavePermission(ModuleName.Defect, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/test-case"
            component={Workbench}
            hasPermission={isHavePermission(ModuleName.Workbench, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/testlab"
            component={TestLab}
            hasPermission={isHavePermission(ModuleName.TestLab, ActionType.VIEW)}
          />

          <PrivateRoute path="/document-management" component={DocumentManagement} />

          <PrivateRoute
            path="/execute"
            component={Execute}
            hasPermission={isHavePermission(ModuleName.Execute, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/pinnacle/execute-test-case"
            component={ExecuteTestCase}
            hasPermission={isHavePermission(ModuleName.Execute, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/enginuity-workbench"
            component={EnginuityWorkbench}
            hasPermission={isHavePermission(ModuleName.EnginuityWorkbench, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/reports"
            component={Reports}
            hasPermission={isHavePermission(ModuleName.Report, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/batch"
            component={Batch}
            hasPermission={isHavePermission(ModuleName.BatchScheduling, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/virtual-tester-monitor"
            component={VirtualTesterMonitor}
            hasPermission={isHavePermission(ModuleName.VirtualTesterMonitor, ActionType.VIEW)}
          />

          <PrivateRoute path="/iframe" component={Iframe} />

          <PrivateRoute
            path="/service-catalogue"
            component={MSA}
            hasPermission={isHavePermission(ModuleName.ServiceCatalogue, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/acceptance-criteria"
            component={AcceptanceCriteria}
            hasPermission={isHavePermission(ModuleName.AcceptanceCriteria, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/acceptance-article"
            component={Acceptance}
            hasPermission={isHavePermission(ModuleName.Acceptance, ActionType.VIEW)}
          />
          <PrivateRoute
            path="/authorise-batch"
            component={AuthoriseBatch}
            hasPermission={isHavePermission(ModuleName.AuthoriseBatch, ActionType.VIEW)}
          />
          <PrivateRoute
            path="/authorise-workbench"
            component={AuthoriseWorkbench}
            hasPermission={isHavePermission(ModuleName.AuthoriseWorkbench, ActionType.VIEW)}
          />
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            hasPermission={isHavePermission(ModuleName.Dashboard, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/test-design"
            component={TestDesign}
            hasPermission={isHavePermission(ModuleName.TestDesign, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/ai-ml"
            component={AI_ML}
            hasPermission={isHavePermission(ModuleName.AIML, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/decision-table"
            component={DecisionTable}
            hasPermission={isHavePermission(ModuleName.DecisionTable, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/document-convert"
            component={DocumentConvert}
            hasPermission={isHavePermission(ModuleName.Document, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/setting"
            component={Setting}
            hasPermission={user?.administrator || isHavePermission(ModuleName.AdminFunction, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/licence-management"
            component={LicenceManagement}
            hasPermission={user?.administrator || isHavePermission(ModuleName.AdminFunction, ActionType.VIEW)}
          />
          <PrivateRoute
            path="/project-management"
            component={ProjectManagement}
            hasPermission={user?.administrator || isHavePermission(ModuleName.AdminFunction, ActionType.VIEW)}
          />
          <PrivateRoute
            path="/permission/:id"
            component={PermissionDetail}
            hasPermission={user?.administrator || isHavePermission(ModuleName.AdminFunction, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/user/:userId"
            component={UserDetail}
            hasPermission={user?.administrator || isHavePermission(ModuleName.AdminFunction, ActionType.VIEW)}
          />

          <PrivateRoute
            path="/role/:id"
            component={RoleDetail}
            hasPermission={user?.administrator || isHavePermission(ModuleName.AdminFunction, ActionType.VIEW)}
          />
          <PrivateRoute path="/403" component={ForbiddenPage} />
        </Main>
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
