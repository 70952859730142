import { createSlice } from '@reduxjs/toolkit';

export interface ProjectManagementState {
  triggerReloadGetListProject: boolean;
  currentSelectedProject: any;
}

const initialState: ProjectManagementState = {
  triggerReloadGetListProject: false,
  currentSelectedProject: null,
};

export const projectManagementSlice = createSlice({
  name: 'ProjectManagement',
  initialState,
  reducers: {
    setTriggerReloadGetListProject: (state, action) => {
      state.triggerReloadGetListProject = action.payload;
    },
    setCurrentSelectedProject: (state, action) => {
      state.currentSelectedProject = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setTriggerReloadGetListProject, setCurrentSelectedProject } = projectManagementSlice.actions;
export default projectManagementSlice.reducer;
