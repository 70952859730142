import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import {
  addNewWorkBenchAPI,
  apiGetMiniDashboard,
  getLinkedDefectStatusAPI,
  getTestCaseDetailAPI,
  getTestCaseScopeAPI,
  getTestCaseSettingAPI,
  getTestStepAPI,
  saveTestStepAPI,
  updateTestCaseAPI,
} from 'app/services/api/workbench';
import { DELETED_ID } from 'types/Common/DeletedID';

export interface IWorkBenchState {
  isLoading: boolean;
  testCaseSelectedId: number;
  isUpdateTestCase: boolean;
  testCaseDetail: any;
  testCaseSetting: any;
  testCaseMiniDashboard: any;
  isLoadingMiniDashboard: boolean;
  testCaseScope: any;
  isLoadingTestCaseScope: boolean;
  linkedDefectStatus: any;
  isLoadingLinkedDefectStatus: boolean;
  isUpdateTestStep: boolean;
  isChangeTestStep: boolean;
  isOpenModalConfirm: boolean;
  isDragable: boolean;
  isMyTest: boolean;
  isUpdateHistory: boolean;
}

const initialState: IWorkBenchState = {
  isLoading: false,
  testCaseSelectedId: 0,
  isUpdateTestCase: false,
  testCaseDetail: [],
  testCaseSetting: [],
  testCaseMiniDashboard: [],
  isLoadingMiniDashboard: false,
  testCaseScope: [],
  isLoadingTestCaseScope: false,
  linkedDefectStatus: [],
  isLoadingLinkedDefectStatus: false,
  isUpdateTestStep: true,
  isChangeTestStep: false,
  isOpenModalConfirm: false,
  isDragable: true,
  isMyTest: false,
  isUpdateHistory: false,
};

export const getTestCaseDetail = createAsyncThunk('testCase/getTestCaseDetail', async (params: any) => {
  const response = await getTestCaseDetailAPI(params);
  return response;
});

export const getTestCaseSetting = createAsyncThunk('testCase/getTestCaseSetting', async (mode: number) => {
  const response = await getTestCaseSettingAPI(mode);
  return response;
});

export const addNewWorkBench = createAsyncThunk('testCase/addNewWorkBench', async (params: any) => {
  try {
    const res: any = await addNewWorkBenchAPI(params);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', typeof error == 'string' ? error : error?.message);
  }
});

export const updateTestCase = createAsyncThunk('testCase/updateTestCase', async (params: any) => {
  try {
    const res: any = await updateTestCaseAPI(params);
    if (res) {
      openNotificationWithIcon('success', res.data);
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', typeof error == 'string' ? error : error?.message);
  }
});

export const getTestCaseDashboard = createAsyncThunk('testCase/getTestCaseDashboard', async (id: number) => {
  const response = await apiGetMiniDashboard(id);
  return response;
});

export const getTestCaseScope = createAsyncThunk('testCase/getTestCaseScope', async (id: number) => {
  const response = await getTestCaseScopeAPI(id);
  return response;
});

export const getLinkedDefectStatus = createAsyncThunk('testCase/getLinkedDefectStatus', async (id: number) => {
  const response = await getLinkedDefectStatusAPI(id);
  return response;
});

// Test Step ..............
export const getTestStep = createAsyncThunk('testCase/getTestStep', async (id: number) => {
  const response = await getTestStepAPI(id);
  return response.data;
});

export const saveTestStep = createAsyncThunk('testCase/saveTestStep', async (id: number) => {
  const response = await saveTestStepAPI(id);
  return response.data;
});

export const workbenchSlice = createSlice({
  name: 'workbench',
  initialState,
  reducers: {
    setTestCaseSelectedId: (state, action) => {
      if (!state.isUpdateTestStep) return;
      state.testCaseSelectedId = action.payload;
    },
    triggerUpdateTestCase: state => {
      state.isUpdateTestCase = !state.isUpdateTestCase;
    },
    clearTestCaseDetail: state => {
      state.testCaseDetail = null;
    },
    triggerLoadingMiniDashboard: state => {
      state.isLoadingMiniDashboard = !state.isLoadingMiniDashboard;
    },
    triggerLoadingTestCaseScope: state => {
      state.isLoadingTestCaseScope = !state.isLoadingTestCaseScope;
    },
    triggerLoadingLinkedDefectStatus: state => {
      state.isLoadingLinkedDefectStatus = !state.isLoadingLinkedDefectStatus;
    },
    triggerUpdateTestStep: (state, action) => {
      state.isUpdateTestStep = action.payload;
    },
    triggerChangeTestStep: (state, action) => {
      state.isChangeTestStep = action.payload;
    },
    triggerOpenModalConfirm: (state, action) => {
      state.isOpenModalConfirm = action.payload;
    },
    setIsDragable: (state, action) => {
      state.isDragable = action.payload;
    },
    setMyTest: (state, action) => {
      state.isMyTest = action.payload;
    },
    triggerUpdateHistory: state => {
      state.isUpdateHistory = !state.isUpdateHistory;
    },
  },
  extraReducers: builder => {
    builder.addCase(getTestCaseDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseDetail = action.payload;
    });

    builder.addCase(getTestCaseDetail.rejected, (state, action) => {
      if (action?.error?.message?.includes('has been deleted')) {
        state.testCaseSelectedId = DELETED_ID;
        state.testCaseDetail = null;
      } else {
        state.testCaseSelectedId = 0;
      }
      state.isUpdateTestCase = !state.isUpdateTestCase;
    });

    builder.addCase(getTestCaseDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseMiniDashboard = action.payload?.rs?.miniDashboardItems;
      state.isLoadingMiniDashboard = false;
    });

    builder.addCase(getTestCaseSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseSetting = action.payload;
    });

    builder.addCase(getTestCaseScope.fulfilled, (state, action) => {
      state.isLoading = false;
      state.testCaseScope = action.payload;
      state.isLoadingTestCaseScope = false;
    });

    builder.addCase(getLinkedDefectStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.linkedDefectStatus = action.payload;
      state.isLoadingLinkedDefectStatus = false;
    });

    builder.addCase(saveTestStep.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdateTestStep = false;
      state.isChangeTestStep = false;
    });
  },
});

// export reducer action
export const {
  setTestCaseSelectedId,
  triggerUpdateTestCase,
  triggerLoadingMiniDashboard,
  triggerLoadingTestCaseScope,
  triggerLoadingLinkedDefectStatus,
  triggerUpdateTestStep,
  triggerChangeTestStep,
  triggerOpenModalConfirm,
  setIsDragable,
  clearTestCaseDetail,
  setMyTest,
  triggerUpdateHistory,
} = workbenchSlice.actions;

export default workbenchSlice.reducer;
