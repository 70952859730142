/* eslint-disable prettier/prettier */

import client from '.';

export interface IAddUser {
  id?: string;
  userName: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  status: number | string;
}

export const getUsersAPI = params => {
  return client.post('/Users/get-users', params).then(res => res.data);
};

export const getCompanySelectAPI = () => {
  return client.get('/Companies/get-company-select').then(res => res.data);
};

export const getUserDetailAPI = (id: string) => {
  return client.get(`Users/get-user-detail?userId=${id}`).then(res => res.data);
};

export const getStatusAPI = () => {
  return client.get(`/Users/get-list-user-status`).then(res => res.data);
};

export const addOrEditUserAPI = (params: IAddUser) => {
  return client.post('/Users/create-update-user', params).then(res => res.data);
};

export const deleteUserAPI = (id: string) => {
  return client.delete(`Users/delete-user?id=${id}`).then(res => res.data);
};

export const updateProfileAPI = (params: any) => {
  return client.post('/Users/update-profile', params).then(res => res.data);
};

export const changePassWordAPI = (params: any) => {
  return client.post('/Users/change-password', params).then(res => res.data);
};
