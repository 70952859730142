import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from './pages/LoginPage/auth.slice';
import userStoriesReducer from './pages/UserStories/UserStories.slice';
import testDesignReducer from './pages/TestDesign/TestDesign.slice';
import workbenchReducer from './pages/Workbench/Workbench.slice';
import releaseReducer from './pages/ReleasePage/release.slice';
import bugReducer from './pages/Bugs/Bugs.slice';
import virtualTesterMonitorReducer from './pages/VirtualTesterMonitor/VirtualTesterMonitor.slice';
import IMsaReducer from './pages/MSA/msa.slice';
import enginuityWorkbenchReducer from './pages/EnginuityWorkbench/Workbench.slice';
import authoriseWorkbenchSlice from './pages/AuthoriseWorkbench/Workbench.slice';
import acceptanceCriteriaReducer from './pages/AcceptanceCriteria/AcceptanceCriteria.slice';
import SettingSliceReducer from './pages/Setting/RolesAndPermission/Setting.slice';
import acceptanceArticleReducer from './pages/Acceptance/Acceptance.slice';
import LicenceManagement from './pages/Setting/LicenceManagement/LicenceManagement.slice';
import executeReducer, {
  setTestStepId,
  setFilterYourAssignedExecution,
  setTestStepDetailDefect,
  triggerIsUpdateTestCase,
} from './pages/Execute/Execute.slice';
import batchReducer from './pages/Batch/Batch.slice';
import authoriseBatchReducer from './pages/AuthoriseBatch/AuthoriseBatch.slice';
import testLabReducer from './pages/TestLab/TestLab.slice';
import docsManagementReducer from './pages/DocumentManagement/DocsManagement.slice';
import AIReducer from './pages/AI&ML/AI.slice';
import DocumentConvertReducer from './pages/DocumentConvert/DocumentConvert.slice';
import NotificationReducer from './pages/HomePage/Notifications/Notification.slide';
import SettingReducer from './pages/Setting/RolesAndPermission/Setting.slice';
import ProjectManagement from './pages/Setting/ProjectManagement/ProjectManagement.slice';
import DecisionTableReducer from './pages/DecisionTable/DecisionTable.slice';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getPersistConfig } from 'redux-deep-persist';

const reducers = combineReducers({
  auth: authReducer,
  release: releaseReducer,
  userStories: userStoriesReducer,
  testDesign: testDesignReducer,
  bugs: bugReducer,
  workbench: workbenchReducer,
  enginuityWorkbench: enginuityWorkbenchReducer,
  authoriseWorkbench: authoriseWorkbenchSlice,
  batch: batchReducer,
  authoriseBatch: authoriseBatchReducer,
  virtualTesterMonitor: virtualTesterMonitorReducer,
  IMsa: IMsaReducer,
  acceptanceCriteria: acceptanceCriteriaReducer,
  acceptanceArticle: acceptanceArticleReducer,
  settingSlice: SettingSliceReducer,
  testLab: testLabReducer,
  execute: executeReducer,
  docsManagement: docsManagementReducer,
  AI: AIReducer,
  documentConvert: DocumentConvertReducer,
  setting: SettingReducer,
  notification: NotificationReducer,
  licenceManagement: LicenceManagement,
  projectManagement: ProjectManagement,
  decisionTableSlice: DecisionTableReducer,
});

const persistConfig = getPersistConfig({
  key: 'pinnacle',
  storage,
  whitelist: [
    'auth',
    'release.isAssignToMe',
    'release.scrollHeaderRelease',
    'release.scrollRightRelease',
    'userStories.isAssignToMe',
    'workbench.isMyTest',
    'enginuityWorkbench.isMyTest',
    'acceptanceCriteria.isAssignToMe',
    'acceptanceArticle.isAssignToMeBusinessAcceptance',
    'acceptanceArticle.isAssignToMeAcceptanceItem',
    'testLab.isAssignToMe',
    'execute.isUpdateTestCase',
    'execute.filterYourAssignedExecution',
    'testDesign.isAssignToMeTestDesign',
    'testDesign.isAssignToMeTestDesignAcceptance',
    'AI.applicationSelected',
    'AI.componentSelected',
    'AI.pageTypeSelected',
    'AI.applicationPageSelected',
    'AI.tabPageSelected',
  ],
  rootReducer: reducers,
});
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

window.addEventListener('storage', (e: any) => {
  try {
    let oldValue = e.oldValue && JSON.parse(e.oldValue);
    let newValue = e.newValue && JSON.parse(e.newValue);
    let oldExecute = oldValue?.execute && JSON.parse(oldValue.execute);
    let newExecute = newValue?.execute && JSON.parse(newValue.execute);
    if (oldExecute?.isUpdateTestCase !== newExecute?.isUpdateTestCase) {
      store.dispatch(triggerIsUpdateTestCase());
    }
  } catch (error) {}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
