import { createSlice } from '@reduxjs/toolkit';

export interface NotificationState {
  notificationNumber: string;
}

const initialState: any = {
  notificationNumber: '0',
};

export const notificationSlide = createSlice({
  name: 'notificationSlide',
  initialState,
  reducers: {
    setNotificationNumber: (state, action) => {
      state.notificationNumber = action.payload;
    },
  },
});

export const { setNotificationNumber } = notificationSlide.actions;

export default notificationSlide.reducer;
