/**
 * Asynchronously loads the component for ReleasePage
 */

import { lazyLoad } from 'utils/loadable';

export const AuthoriseWorkbench = lazyLoad(
  () => import('./index'),
  module => {
    return module.AuthoriseWorkbench;
  },
);
