/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from 'reportWebVitals';
// Initialize languages

import './assets/styles/main.css';
import './assets/styles/responsive.css';
import './assets/styles/global.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import locale from 'antd/es/locale/en_GB';
import moment from 'moment';
import { ConfigProvider } from 'antd';
moment.locale('en-gb', {
  week: {
    dow: 1,
  },
});

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
let persistor = persistStore(store);

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <ConfigProvider locale={locale as any}>
          <App />
        </ConfigProvider>
      </HelmetProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
