import client from '.';

export const getDefectGridChartAPI = () => {
  return client.get('/DefectRegister/get-defect-grid').then(res => res.data);
};

export const getDefectDetailAPI = (id: number) => {
  return client.get(`Defects/get-defect-detail?id=${id}`).then(res => res.data);
};

export const deleteDefectAPI = params => {
  return client.delete('/Defects/delete-defect', { data: params });
};

export const getDefectHistoryAPI = (defectId: number) => {
  return client.get('/Defects/get-defect-history', { params: { id: defectId } });
};

export const getDefectTestCoverageAPI = params => {
  return client.get('/DefectTestCoverage/get-defect-test-coverage', { params: params });
};

export const assignTestCaseAPI = params => {
  return client.post('/DefectTestCoverage/assign-testcase', params);
};

export const deleteTestCaseAPI = params => {
  return client.post('/DefectTestCoverage/delete-testcase', params);
};

export const getSettingAPI = () => {
  return client.get(`/Defects/get-settings`).then(res => res.data);
};

export const getSettingIdAPI = (id: number) => {
  return client.get(`Common/get-new-id?type=${id}`).then(res => res.data);
};

export const getRiskMinidashboardAPI = (param: any) => {
  return client.post(`Defects/get-risk-minidashboard`, { ...param });
};

export const addNewDefectAPI = (param: any) => {
  return client.post(`Defects/create-defect`, { ...param });
};

export const updateDefectAPI = (param: any) => {
  return client.post('/Defects/update-defect', { ...param });
};

export const apiGetMiniDashboard = (id: number) => {
  return client.get(`/Defects/get-defect-minidashboard?id=${id}`).then(res => res.data);
};

// Workflow
export const getWorkflowAPI = () => {
  return client.get(`/Defects/get-defect-workflow`).then(res => res.data);
};

export const getWorkflowReopenDefectAPI = (id: number) => {
  return client.post(`Defects/defect-workflow-reopen?id=${id}`).then(res => res.data);
};

export const getWorkflowDeferDefectAPI = (id: number) => {
  return client.post(`Defects/defect-workflow-defer?id=${id}`).then(res => res.data);
};

export const getWorkflowCloseDefectAPI = (param: any) => {
  return client.post(`Defects/defect-workflow-closed`, { ...param }).then(res => res.data);
};

export const getWorkflowAssignAPI = (title: string, param: any) => {
  return client.post(`/Defects/defect-workflow-${title}`, { ...param }).then(res => res.data);
};
