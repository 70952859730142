import client from '.';

export const getListProjectAPI = () => {
  return client.get('/Projects').then(res => res.data);
};

export const selectProjectAPI = projectId => {
  return client.post(`/Projects/${projectId}`).then(res => res.data);
};

export const getProjectsAPI = params => {
  return client.post(`/Projects/get-projects`, params).then(res => res.data);
};

export const getDetailProjectAPI = projectId => {
  return client.post(`/Projects/${projectId}`).then(res => res.data);
};

export const createProjectAPI = params => {
  return client.post(`/Projects/create-project`, params).then(res => res.data);
};

export const getProjectDetailRolesAPI = params => {
  return client.post(`/Projects/get-project-detail-roles`, params).then(res => res.data);
};

export const updateProjectRoleAPI = params => {
  return client.post(`/Projects/update-project-role`, params).then(res => res.data);
};

export const getProjectDetailUsersAPI = projectId => {
  return client.get(`/Projects/get-project-detail-users?projectId=${projectId}`).then(res => res.data);
};

export const deactivateProjectAPI = projectId => {
  return client.post(`/Projects/deactivate-project?projectId=${projectId}`).then(res => res.data);
};

export const activateProjectAPI = projectId => {
  return client.post(`/Projects/activate-project?projectId=${projectId}`).then(res => res.data);
};

export const getProjectTypesAPI = () => {
  return client.get(`/Projects/get-project-types`).then(res => res.data);
};
