import { createSlice } from '@reduxjs/toolkit';

export interface SettingState {
  isLoading: boolean;
  activeTab: string;
  isUpdateListUser: boolean;
}

const initialState: SettingState = {
  isLoading: false,
  activeTab: '1',
  isUpdateListUser: false,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    triggerIsUpdateListUser: state => {
      state.isUpdateListUser = !state.isUpdateListUser;
    },
  },
  extraReducers: builder => {},
});

export const { setActiveTab, triggerIsUpdateListUser } = settingSlice.actions;
export default settingSlice.reducer;
