/* eslint-disable prettier/prettier */
import { Button, Col, Form, Input, Modal, Row, Select, Typography } from 'antd';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ValidationError, ValidationSchema } from 'app/constants';
import { useAppSelector, RootState } from 'app/store';
import { changePassWordAPI } from 'app/services/api/user';
const { Text, Title } = Typography;

interface ModalChangePasswordProps {
  onCancel: (value: boolean) => void;
  open: boolean;
}

const ModalChangePassword = ({ onCancel, open }: ModalChangePasswordProps) => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [value, setValue] = useState('');
  const _onSave = async () => {
    try {
      await form
        .validateFields()
        .then(async then => {
          const formValues = await form.getFieldsValue();
          const res = await changePassWordAPI(formValues);
          openNotificationWithIcon('success', res?.message);
          handleCancelModal();
        })
        .catch(error => {
          if (error?.message) {
            openNotificationWithIcon('error', error?.message);
          }
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    if (user) {
      const initialForm = {
        firstName: user?.first_Name,
        lastName: user?.last_Name,
        fullName: user?.fullname,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        roleTitle: user?.roleTitle,
      };
      form.setFieldsValue(initialForm);
    } else {
      form.resetFields();
    }
  }, [open, user]);

  const _renderFooter = () => {
    return (
      <Row justify="end" gutter={[12, 0]}>
        <Button onClick={handleCancelModal}>Cancel</Button>
        <Button type="primary" style={{ marginRight: 10 }} onClick={_onSave}>
          Save
        </Button>
      </Row>
    );
  };

  const handleCancelModal = () => {
    onCancel(false);
    form.resetFields();
  };

  return (
    <Modal
      width={800}
      footer={_renderFooter()}
      title="Change Password"
      destroyOnClose={true}
      closeIcon={false}
      centered
      open={open}
      onCancel={handleCancelModal}
      style={{ verticalAlign: 'top', marginTop: '100px' }}
      className="change-password"
    >
      <Form layout="vertical" form={form} autoComplete="off">
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item
              name="oldPassword"
              label="Current Password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Current Password',
                },
              ]}
            >
              <Input.Password placeholder="Enter Current Password" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value! || ValidationSchema.checkPassSpecial.test(getFieldValue('newPassword'))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(ValidationError.password));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Enter New Password" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="confirmNewPassword"
              label="Retype New Password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please retype New Password',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The New Password that you entered does not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Retype New Password" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <span>
              Use at least 8 characters with a mix of upper and lower cases, number and special characters. Must not
              contain your name or user name.
            </span>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalChangePassword;
