import client from '.';

// test script
export const getTestScriptEnginuityWorkbenchAPI = (id: number) => {
  return client.get('/TestAutomation/get-testcase-component', { params: { testCaseId: id } });
};

export const getComponentSelectionAPI = params => {
  return client.get('/TestAutomation/get-component-selection', {
    params: { testCaseId: params.id, searchString: params.searchString },
  });
};

export const linkComponentToTestCaseAPI = params => {
  return client.post('/TestAutomation/link-component-to-testcase', params);
};

export const validateTestSyntaxAPI = params => {
  return client.get('/TestAutomation/test-script-validate-syntax', { params: params });
};

export const clearSyntaxResultAPI = params => {
  return client.post('/TestAutTestSteps/clear-syntax-results', params);
};

export const clearTestResultsAPI = params => {
  return client.post('/TestAutomation/clear-test-results', null, { params: params });
};

export const saveTestResultsAPI = id => {
  return client.post(`TestAutomation/save-debug-result?testCaseId=${id}`).then(res => res.data);
};

export const deleteTestScriptAPI = params => {
  return client.post('/TestAutomation/remove-component-from-testcase', params);
};

export const moveTestScriptAPI = params => {
  return client.post('/TestAutomation/move-component', params);
};

export const selectedRunScriptAPI = params => {
  return client.post('/TestAutomation/select-run-component-action', params);
};

export const runTestAPI = params => {
  return client.post('/DebugRunner/run-test', params);
};

export const getRunTestOptionAPI = () => {
  return client.get('/DebugRunner/get-run-test-options');
};
// end test script

// ===================== Test Step ======================= //
export const getTestStepEnginuityWorkbenchAPI = (param: {
  componentId: number;
  intTestCaseLinkId: number;
  componentVersion: number;
  isLoadResult: boolean;
}) => {
  return client.get(
    `/TestAutTestSteps/get-component-test-step?componentId=${param?.componentId}&intTestCaseLinkId=${param?.intTestCaseLinkId}&componentVersion=${param?.componentVersion}&isLoadResult=true&viewTestStepType=2`,
  );
};

export const getReadOnlyTestStepEnginuityWorkbenchAPI = (param: {
  componentId: number;
  intTestCaseLinkId: number;
  componentVersion: number;
}) => {
  return client.get(
    `/TestAutTestSteps/get-component-test-step?componentId=${param?.componentId}&intTestCaseLinkId=${param?.intTestCaseLinkId}&componentVersion=${param?.componentVersion}&isLoadResult=true&viewTestStepType=1`,
  );
};

export const getActionComboAPI = (param: {
  componentId: number;
  componentVersion: number;
  testStepId: number;
  dataColumnCheckBoxValue: number;
}) => {
  return client
    .get(
      `TestAutTestSteps/get-action-word-combo?componentId=${param?.componentId}&componentVersion=${param?.componentVersion}&testStepId=${param?.testStepId}&dataColumnCheckBoxValue=${param?.dataColumnCheckBoxValue}`,
    )
    .then(res => res.data);
};

export const getFieldComboAPI = (param: {
  componentId: number;
  componentVersion: number;
  testStepId: number;
  actionWord: string;
}) => {
  return client.get(
    `TestAutTestSteps/get-object-combo?componentId=${param?.componentId}&componentVersion=${param?.componentVersion}&testStepId=${param?.testStepId}&actionWord=${param?.actionWord}`,
  );
};

export const addNewTestStepAPI = params => {
  return client.post('/TestAutTestSteps/create-test-step', params).then(res => res.data);
};

export const editTestStepAPI = params => {
  return client.put('/TestAutTestSteps/update-test-step', params);
};

export const deleteTestStepAPI = params => {
  return client.delete('/TestAutTestSteps/delete-test-step', { data: params });
};

export const breakPointTestStepAPI = (param: { testStepInstance: number; breakPoint: boolean }) => {
  return client.post(
    `/TestAutTestSteps/break-point-test-step?testStepInstance=${param?.testStepInstance}&breakPoint=${param?.breakPoint}`,
  );
};

export const declareTransactionLoopAPI = (param: any) => {
  return client.post(`/TestAutTestSteps/declare-transaction-loop`, param);
};

export const pasteTestStepAPI = params => {
  return client.post('/TestAutTestSteps/paste-copy-test-step', params);
};

export const insertTestStepAPI = params => {
  return client.post('/TestAutTestSteps/insert-copy-test-step', params);
};

export const undoTestStepAPI = params => {
  return client.post('/TestAutTestSteps/undo-last-change-test-step', params);
};

export const initialDataValueAPI = params => {
  return client.get('/TestAutTestSteps/initialise-data-value', { params: params });
};

export const getTestDataEnginuityWorkbenchAPI = (param: {
  componentId: number;
  testCaseLinkId: number;
  componentVersion: number;
}) => {
  return client.get(
    `/TestAutTestSteps/get-test-data?componentId=${param?.componentId}&componentVersion=${param?.componentVersion}&testCaseLinkId=${param?.testCaseLinkId}`,
  );
};

export const deleteTestDataAPI = (param: any) => {
  return client.delete(`/TestAutTestSteps/delete-test-step-data`, { data: param });
};

export const getComponentParentDataAPI = (componentId: number) => {
  return client.get(`/TestAutTestSteps/get-component-parent?componentId=${componentId}`);
};

export const changeParentTestCase = (param: {
  componentId: number;
  componentVersion: number;
  testCaseId: string | number;
}) => {
  return client.get(
    `/TestAutTestSteps/change-parent-test-case?componentId=${param?.componentId}&componentVersion=${param?.componentVersion}&testCaseId=${param?.testCaseId}`,
  );
};

export const insertTestData = params => {
  return client.post('/TestAutTestSteps/insert-test-step-data', params);
};

export const addColumnTestData = params => {
  return client.post('/TestAutTestSteps/add-transaction-test-data', params);
};

export const deleteColumnTestData = param => {
  return client.delete('/TestAutTestSteps/delete-trans-test-data', { data: param });
};

export const copyAndPasteTestData = param => {
  return client.post('/TestAutTestSteps/paste-transaction-test-data', param);
};

export const updateTestDataValue = param => {
  return client.post('/TestAutTestSteps/update-test-data-value', param);
};

export const highlightFieldAPI = payload => {
  return client.post('/TestAutTestSteps/highlight-field', payload);
};

export const getFieldDetailsAPI = params => {
  return client.get('/TestAutTestSteps/get-field-detail', { params });
};

export const updateFieldDetailsAPI = payload => {
  return client.post('/TestAutTestSteps/create-update-field', payload);
};
// ====================================================== //
