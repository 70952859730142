/**
 * Asynchronously loads the component for ReleasePage
 */

import { lazyLoad } from 'utils/loadable';

export const TestDesign = lazyLoad(
  () => import('./index'),
  module => {
    return module.TestDesign;
  },
);
