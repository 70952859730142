import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { reloadMiniDashboarDefect } from 'app/models';
import {
  addNewDefectAPI,
  apiGetMiniDashboard,
  getDefectDetailAPI,
  getRiskMinidashboardAPI,
  getSettingAPI,
  updateDefectAPI,
} from 'app/services/api/bug';
import { DELETED_ID } from 'types/Common/DeletedID';

export interface IBugState {
  isLoading: boolean;
  defectSelectedId: number;
  isUpdateDefect: boolean;
  defectDetail: any;
  listSetting: any;
  listMiniDasboard: any;
  isLoadingMiniDashboard: boolean;
  isUpdateHistory: boolean;
}

const initialState: IBugState = {
  isLoading: false,
  defectSelectedId: 0,
  isUpdateDefect: false,
  defectDetail: [],
  listSetting: [],
  listMiniDasboard: [],
  isLoadingMiniDashboard: false,
  isUpdateHistory: false,
};

export const getDefectDetail = createAsyncThunk('release/getDefectDetail', async (params: any) => {
  const response = await getDefectDetailAPI(params);
  return response;
});

export const getSetting = createAsyncThunk('release/getSetting', async () => {
  const response = await getSettingAPI();
  return response;
});

export const getRiskMinidashboard = createAsyncThunk('release/getRiskMinidashboard', async (params: any) => {
  const response = await getRiskMinidashboardAPI(params);
  return response.data;
});

export const addNewDefect = createAsyncThunk('release/addNewDefect', async (params: any) => {
  try {
    const res: any = await addNewDefectAPI(params);
    if (res) {
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', error?.errors?.DefectTitle || error?.errors?.DefectDescription || error);
  }
});

export const updateDefect = createAsyncThunk('release/updateDefect', async (params: any) => {
  try {
    const res: any = await updateDefectAPI(params);
    if (res) {
      openNotificationWithIcon('success', res.data);
      return res.data;
    }
  } catch (error: any) {
    openNotificationWithIcon('error', typeof error == 'string' ? error : error?.message);
  }
});

export const getDefectDashboard = createAsyncThunk('release/getDefectDashboard', async (id: number) => {
  const response = await apiGetMiniDashboard(id);
  return response;
});

export const bugSlice = createSlice({
  name: 'bugs',
  initialState,
  reducers: {
    setDefectSelectedId: (state, action) => {
      state.defectSelectedId = action.payload;
    },
    triggerUpdateDefect: state => {
      state.isUpdateDefect = !state.isUpdateDefect;
    },
    clearDefectDetail: state => {
      state.defectDetail = null;
    },
    triggerLoadingMiniDashboard: state => {
      state.isLoadingMiniDashboard = !state.isLoadingMiniDashboard;
    },
    triggerUpdateHistory: state => {
      state.isUpdateHistory = !state.isUpdateHistory;
    },
  },
  extraReducers: builder => {
    builder.addCase(getDefectDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.defectDetail = action.payload.defectDetail;
    });

    builder.addCase(getDefectDetail.rejected, (state, action) => {
      if (action?.error?.message?.includes('has been deleted')) {
        state.defectSelectedId = DELETED_ID;
        state.defectDetail = null;
      } else {
        state.defectSelectedId = 0;
      }
      state.isUpdateDefect = !state.isUpdateDefect;
    });

    builder.addCase(getDefectDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.listMiniDasboard = action.payload.data;
      state.isLoadingMiniDashboard = false;
    });

    builder.addCase(getSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.listSetting = action.payload;
    });

    builder.addCase(updateDefect.fulfilled, (state, action) => {
      if (reloadMiniDashboarDefect?.includes(action?.meta?.arg?.modifiedFields[0]?.fieldName)) {
        state.isLoadingMiniDashboard = true;
      }
    });
  },
});

// export reducer action
export const {
  setDefectSelectedId,
  triggerUpdateDefect,
  triggerLoadingMiniDashboard,
  clearDefectDetail,
  triggerUpdateHistory,
} = bugSlice.actions;

export default bugSlice.reducer;
