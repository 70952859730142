export interface ITestStepRun {
  testStepRunId: number;
  testStepId: number;
  testStepActivity: string;
  testStepAdditionalInformation: string;
  runCommencedOn: string;
  runCommencedBy: number;
  statusType: string;
  hasAttachment: number;
  actualResult: string;
  testStepExpectedResult: string;
  backColor: string;
  backColor2: string;
  isUploaded: boolean;
  attachmentUrl: string;
}

export const statusTestStep = [
  { name: 'Not Run', color: '#4682b4', value: 'not-run' },
  { name: 'Blocked', color: '#ffd700', value: 'blocked' },
  { name: 'Failed', color: '#f04438', value: 'failed' },
  { name: 'Passed', color: '#027a48', value: 'passed' },
];
export interface IExecutionRunAggregate {
  totalTests: number;
  totalSteps: number;
  totalStepsNotRun: number;
  totalStepsFailed: number;
  totalStepsBlocked: number;
  totalStepsPassed: number;
}
export interface IExecutionSet {
  executionSetId: number;
  title: string;
  description: string | null;
  workflow: string;
  completionDueDate: string | null;
  dueDateStr: string | null;
  startByDate: string | null;
  startDateStr: string | null;
  executionSetRunId: number;
  statusType: string;
  runCommencedBy: number;
  runCommencedOn: string | null;
  statusChangedBy: string | null;
  statusChangedOn: string | null;
  executionRunAggregate: IExecutionRunAggregate;
}
export interface ItTestCaseRun {
  testCaseRunId: number;
  testCaseId: number;
  title: string;
  description: number;
  runCommencedOn: string;
  runCommencedBy: number;
  statusType: string;
  testCaseAssignedTo: number;
  testCasePriority: string;
  executionSetRunId: number;
  backColor: string;
  backColor2: string;
}
export interface ISelectExecutionSet {
  executionSetId: number;
  executionSetTitle: string;
  testCaseRuns: ItTestCaseRun[];
}

export interface ItestStepDetailDefect {
  testStepRunId: number;
  testStepId: number;
  testStepActivity: string;
  testStepAdditionalInformation: string;
  runCommencedOn: Date;
  runCommencedBy: number;
  statusType: string;
  hasAttachment: number;
  actualResult: String | null;
  testStepExpectedResult: string;
  attachment: String | null;
  attachmentDescription: String | null;
  attachmentUrl: String | null;
  isUploaded: boolean;
  attachmentMimeType: String | null;
  attachmentExtension: String | null;
}
