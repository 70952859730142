import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { getQuoteDetailAPI, getSummaryServiceCatalougeAPI, getWorkPackagesDetailAPI } from 'app/services/api/msa';
import { DELETED_ID } from 'types/Common/DeletedID';

export interface IMsa {
  isLoading: boolean;
  listWorkPackages: any;
  workPackagesDetail: any;
  setting: any;
  isUpdateWorkPackagesDetail: boolean;
  requirementDetail: any;
  seletedRequirementID: number;
  selectedItemWorkPackages: any;
  listSummaryServiceCatalogue: any;
  quoteDetail: any;
  isDisableApprovals: boolean;
}

const initialState: IMsa = {
  isLoading: false,
  listWorkPackages: [],
  workPackagesDetail: null,
  setting: [],
  isUpdateWorkPackagesDetail: false,
  requirementDetail: null,
  seletedRequirementID: 0,
  selectedItemWorkPackages: 0,
  listSummaryServiceCatalogue: [],
  quoteDetail: null,
  isDisableApprovals: false,
};

export const getWorkPackagesDetail = createAsyncThunk(
  'msa/getWorkPackagesDetail',
  async (id: any, { rejectWithValue }) => {
    if (id) {
      try {
        const response = await getWorkPackagesDetailAPI(id);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  },
);

export const getSummaryServiceCatalougeSlice = createAsyncThunk(
  'msa/getSummaryServiceCatalouge',
  async (params: any) => {
    const response = await getSummaryServiceCatalougeAPI(params);
    return response;
  },
);

export const getQuoteDetailSlice = createAsyncThunk('msa/getQuoteDetail', async (params: any) => {
  const response = await getQuoteDetailAPI(params);
  return response;
});

export const msaSlice = createSlice({
  name: 'msa',
  initialState,
  reducers: {
    setting: (state, action) => {
      state.setting = action.payload;
    },
    updateWorkPackagesDetail: state => {
      state.isUpdateWorkPackagesDetail = !state?.isUpdateWorkPackagesDetail;
    },
    setRequirementDetail: (state, action) => {
      state.requirementDetail = action.payload;
    },
    setSeletedRequirementID: (state, action) => {
      state.seletedRequirementID = action.payload;
    },
    setSelectedItemWorkPackages: (state, action) => {
      state.selectedItemWorkPackages = action.payload;
    },
    setWorkPackages: (state, action) => {
      state.listWorkPackages = action.payload;
    },
    setIsDisableApprovals: (state, action) => {
      state.isDisableApprovals = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getWorkPackagesDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.workPackagesDetail = action.payload;
    });
    builder.addCase(getWorkPackagesDetail.rejected, (state, action: any) => {
      if (action?.payload?.includes('has been deleted')) {
        state.selectedItemWorkPackages = DELETED_ID;
      } else {
        state.selectedItemWorkPackages = state.listWorkPackages[0]?.id;
      }
      openNotificationWithIcon('error', action.payload);
      state.isUpdateWorkPackagesDetail = !state.isUpdateWorkPackagesDetail;
    });
    builder.addCase(getSummaryServiceCatalougeSlice.fulfilled, (state, action) => {
      state.listSummaryServiceCatalogue = action.payload?.data;
    });
    builder.addCase(getQuoteDetailSlice.fulfilled, (state, action) => {
      state.quoteDetail = action.payload?.data;
    });
    builder.addCase(getQuoteDetailSlice.rejected, (state, action) => {
      state.quoteDetail = null;
    });
  },
});

export const {
  setting,
  updateWorkPackagesDetail,
  setRequirementDetail,
  setSeletedRequirementID,
  setSelectedItemWorkPackages,
  setWorkPackages,
  setIsDisableApprovals,
} = msaSlice.actions;

export default msaSlice.reducer;
