import { createSlice } from '@reduxjs/toolkit';

export interface DecisionTableState {
  isLoading: boolean;
  isUpdateDecisionTable: boolean;
  activeGroupId: string;
}

const initialState: DecisionTableState = {
  isLoading: false,
  isUpdateDecisionTable: false,
  activeGroupId: '',
};

export const DecisionTableSlice = createSlice({
  name: 'user-stories',
  initialState,
  reducers: {
    triggerIsUpdateDecisionTable: state => {
      state.isUpdateDecisionTable = !state.isUpdateDecisionTable;
    },
    setActiveGroupId: (state, action) => {
      state.activeGroupId = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const { setActiveGroupId, triggerIsUpdateDecisionTable } = DecisionTableSlice.actions;

export default DecisionTableSlice.reducer;
